@import "minima";

p.hidden {
	visibility: hidden;
	height: 0px;
	}
	
form p label {
	display: block;
	}
	
form p input {
	width: 300px;
	background-color: #EEE;
	}

form p textarea {
	width: 300px;
	height: 100px;
	background-color: #EEE;
	}

form button {
	width: 150px;
	height: 30px;
	margin-top: 20px;
	}
	
p.note {
	color: #C0C0C0;
	max-width: 500px;
	}
